import APIClient from './index';
import _ from 'lodash';

export const fetchVariantsQueue = data => {
  return new Promise((resolve, reject) => {
    APIClient.get(`Queues/Variants?${new URLSearchParams(data).toString()}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const fetchVariantsQueueFilters = data => {
  return new Promise((resolve, reject) => {
    APIClient.get(`Queues/Variants/filters?${new URLSearchParams(data).toString()}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const fetchPinsQueue = data => {
  return new Promise((resolve, reject) => {
    APIClient.get(`Queues/Pins?${new URLSearchParams(data).toString()}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};
