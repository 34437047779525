import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';
import _ from 'lodash';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import { faCopy, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

import { copyToClipboard } from '../../../helpers/helpers';
import { updateVariant } from '../../../api/products';
import { updatePin } from '../../../api/pins';

import ProductPreview from './ProductPreview';

import './ProductEditView.scss';

class ProductEditView extends Component {
  static propTypes = {
    catalog: PropTypes.object.isRequired,
    syncVariantsQueue: PropTypes.func.isRequired,
    syncPinsQueue: PropTypes.func.isRequired,
    selectProduct: PropTypes.func.isRequired,
    getProduct: PropTypes.func.isRequired,
    addProduct: PropTypes.func.isRequired,
    deleteProduct: PropTypes.func.isRequired,
    updateProduct: PropTypes.func.isRequired,
    checkIfUnmatchedPinsCanBeMatchedByRecentVariantMatch: PropTypes.func.isRequired,
    performSearch: PropTypes.func,
    selectedProduct: PropTypes.object,
    selectedTicket: PropTypes.object
  };

  componentDidUpdate(prevProps) {
    const prevId = _.get(prevProps, 'selectedProduct.id');
    const curId = _.get(this.props, 'selectedProduct.id');
    if (prevId !== curId) {
      if (curId) {
        this.syncProduct(curId);
      } else {
        this.cancel();
      }
    }

    const prevTicketId = _.get(prevProps, 'selectedTicket.id');
    const curTicketId = _.get(this.props, 'selectedTicket.id');
    if (prevTicketId !== curTicketId) {
      if (curTicketId && !this.state.product) {
        this.setState({
          newTitle: this.getSelectedTicketTitle(),
          newImage: _.get(this.props, 'selectedTicket.original_image') || _.get(this.props, 'selectedTicket.image') || '',
          newCrop: null
        });
      }
    }
  }

  getSelectedTicketTitle = () => {
    const { selectedTicket } = this.props;
    return (
      selectedTicket.rawTitle ||
      (selectedTicket.title && selectedTicket.title.includes('|') ? selectedTicket.title.split('|')[1].trim() : selectedTicket.title) ||
      ''
    );
  };

  syncProduct = id => {
    const { getProduct } = this.props;
    getProduct(id).then(resp => {
      const category = this.getCategoryFromId(resp.Category_id);
      this.setState({
        product: resp,
        newTitle: _.get(resp, 'title'),
        newImage: _.get(resp, 'image'),
        newBrand: _.get(resp, 'brand'),
        newBrandSelectValue: {
          label: _.get(resp, 'brand'),
          value: _.get(resp, 'brand')
        },
        newTags: _.get(resp, 'tags', []),
        newCategoryId: _.get(resp, 'Category_id'),
        newCategorySelectValue: {
          label: _.get(category, 'name'),
          value: _.get(resp, 'Category_id')
        },
        newFallbackUrl: _.get(resp, 'fallbackUrl') || '',
        newFallbackPrice: _.get(resp, 'fallbackPrice') || '',
        newHidden: _.get(resp, 'isHidden') || false,
        newIsSeoPrerendered: _.get(resp, 'isSeoPrerendered') || false,
        newCrop: null
      });
    });
  };

  state = {
    product: null,
    newTitle: '',
    newImage: '',
    newBrand: '',
    newBrandSelectValue: null,
    newCategoryId: null,
    newCategorySelectValue: null,
    newFallbackPrice: '',
    newFallbackUrl: '',
    newHidden: false,
    newIsSeoPrerendered: false,
    newTags: [],
    newCrop: null
  };

  canCancel = () =>
    !!this.state.newTitle.length ||
    !!this.state.newImage.length ||
    !!this.state.newBrand.length ||
    !!this.state.newCrop ||
    !!this.state.newCategoryId;
  canSave = () => !!this.state.newTitle.length && !!this.state.newImage.length && !!this.state.newBrand.length && !!this.state.newCategoryId;

  selectedTicketIsPin = () => 'Collection_id' in (this.props.selectedTicket || {});

  isEditing = () => !!this.state.product;

  submitForm = (e, completingMatch) => {
    const { updateProduct, addProduct } = this.props;
    const {
      newTitle,
      newCrop,
      newBrand,
      newTags,
      newImage,
      newCategoryId,
      newFallbackPrice,
      newFallbackUrl,
      newHidden,
      newIsSeoPrerendered
    } = this.state;
    e.preventDefault();
    this.setState({ isSaving: true });
    if (this.isEditing()) {
      updateProduct({
        title: newTitle,
        image: newImage,
        brand: newBrand,
        crop: newCrop,
        Category_id: newCategoryId,
        ...(!_.isNaN(parseFloat(newFallbackPrice)) ? { fallbackPrice: parseFloat(newFallbackPrice) } : {}),
        ...(newFallbackUrl ? { fallbackUrl: newFallbackUrl } : {}),
        isHidden: newHidden,
        isSeoPrerendered: newIsSeoPrerendered,
        tags: newTags
      }).finally(() => {
        this.setState({ isSaving: false });
      });
    } else {
      addProduct({
        title: newTitle,
        image: newImage,
        brand: newBrand,
        crop: newCrop,
        Category_id: newCategoryId,
        ...(!_.isNaN(parseFloat(newFallbackPrice)) ? { fallbackPrice: parseFloat(newFallbackPrice) } : {}),
        ...(newFallbackUrl ? { fallbackUrl: newFallbackUrl } : {}),
        isHidden: newHidden,
        isSeoPrerendered: newIsSeoPrerendered,
        tags: newTags
      })
        .then(newProduct => {
          console.log('added product!', { newProduct, completingMatch });
          if (completingMatch) {
            // this.makeTicketMatch(this.props.selectedTicket, newProduct, { closeOnCompletion: !this.selectedTicketIsPin() })
            this.makeTicketMatch(this.props.selectedTicket, newProduct, { closeOnCompletion: false });
          }
        })
        .finally(() => {
          this.setState({ isSaving: false });
        });
    }
  };

  deleteProduct = () => {
    const { deleteProduct } = this.props;
    deleteProduct().then(resp => {
      cogoToast.success('Successfully deleted product!', { hideAfter: 1 });
      this.cancel();
    });
  };

  cancel = () => {
    this.setState({
      product: null,
      newTitle: '',
      newImage: '',
      newBrand: '',
      newFallbackPrice: '',
      newFallbackUrl: '',
      newTags: [],
      newBrandSelectValue: null,
      newCategoryId: null,
      newCategorySelectValue: null,
      newCrop: null,
      newHidden: false,
      newIsSeoPrerendered: false
    });
    this.props.selectProduct();
  };

  getBrandOptions = () => {
    const { catalog } = this.props;
    const { brands } = catalog;
    return brands.map(brand => ({
      value: brand,
      label: brand
    }));
  };

  getCategoryFromId = id => _.find(this.props.catalog.categories, cat => cat.id === id);
  getTagsFromCategory = category => _.filter(this.props.catalog.tags, tag => category && tag.Category_id === category.id);

  getCategoryOptions = () => {
    const { catalog } = this.props;
    const { departments, categories } = catalog;
    return _.orderBy(departments, 'name').map(department => {
      const depCats = _.orderBy(
        _.filter(categories, c => c?.Department_id === department.id),
        'name'
      );
      return {
        label: department.name,
        options: depCats.map(category => ({
          label: category.name,
          value: category.id
        }))
      };
    });
  };

  toggleTag = tag => {
    const { newTags } = this.state;
    console.log('toggling tag', tag);
    const exists = _.find(newTags, oldTag => oldTag.name === tag.name);
    this.setState({
      newTags: exists ? _.filter(newTags, newTag => newTag.id !== tag.id) : [...newTags, tag]
    });
  };

  copy = str => {
    copyToClipboard(str);
    cogoToast.success('Copied!', { hideAfter: 0.5 });
  };

  makeTicketMatch = (ticket, product, options = {}) =>
    new Promise(resolve => {
      const { closeOnCompletion } = options;
      const updateFn = this.selectedTicketIsPin() ? updatePin : updateVariant;
      const postUpdateFn = this.selectedTicketIsPin() ? null : this.props.checkIfUnmatchedPinsCanBeMatchedByRecentVariantMatch;
      this.setState({ isSaving: true });
      updateFn(ticket.id, { Product_id: product.id })
        .then(
          updatedObject => {
            if (this.selectedTicketIsPin()) {
              this.props.syncPinsQueue({ shouldSelectTicket: true });
            } else {
              this.props.syncVariantsQueue();
            }

            postUpdateFn && postUpdateFn(updatedObject);
            if (closeOnCompletion) {
              this.cancel();
              this.props.performSearch('');
              cogoToast.success('Successfully created and matched.', { hideAfter: 1 });
            } else {
              this.syncProduct(product.id);
              cogoToast.success('Successfully matched.', { hideAfter: 1 });
            }
            resolve();
          },
          err => {
            cogoToast.error('There was an issue with this match.');
            resolve();
          }
        )
        .finally(() => {
          this.setState({ isSaving: false });
        });
    });

  render() {
    const { selectedTicket } = this.props;
    const {
      product,
      newTitle,
      newImage,
      newBrand,
      newTags,
      newBrandSelectValue,
      newCategoryId,
      newCategorySelectValue,
      newFallbackUrl,
      newFallbackPrice,
      newHidden,
      newIsSeoPrerendered,
      isSaving
    } = this.state;
    const { variants, pins } = product || {};
    const currentCategory = this.getCategoryFromId(newCategoryId);
    const categoryOptions = this.getCategoryOptions();
    const availableTags = this.getTagsFromCategory(currentCategory);
    return (
      <div className='product-edit-view-outer-container panel'>
        {this.canCancel() && (
          <div onClick={this.cancel} className='cancel icn'>
            <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
          </div>
        )}
        <ProductPreview
          product={product}
          title={newTitle}
          brand={newBrand}
          image={newImage}
          category={currentCategory}
          isEditing={this.isEditing()}
          updateCrop={crop => this.setState({ newCrop: crop })}
          updateImageUrl={newUrl => this.setState({ newImage: newUrl })}
        />
        <form onSubmit={this.submitForm} className='product-edits'>
          {!!availableTags.length && (
            <div className='available-tags-container'>
              {availableTags.map(tag => {
                const active = _.find(newTags, newTag => newTag.id === tag.id);
                return (
                  <div key={tag.id} onClick={() => this.toggleTag(tag)} className={cn('available-tag', { active })}>
                    {tag.name}
                  </div>
                );
              })}
            </div>
          )}
          <div className='input-row'>
            <input
              placeholder={product ? 'Product Title' : 'New Product Title'}
              value={newTitle}
              onChange={({ target }) => this.setState({ newTitle: target.value })}
            />
            {selectedTicket && (
              <div onClick={() => this.setState({ newTitle: this.getSelectedTicketTitle() })} className='icon'>
                <FontAwesomeIcon icon={faCopy}></FontAwesomeIcon>
              </div>
            )}
          </div>
          <Creatable
            className='brand-selection select'
            placeholder='Brand'
            value={newBrandSelectValue}
            onChange={data => this.setState({ newBrand: data.value, newBrandSelectValue: data })}
            options={this.getBrandOptions()}
          />
          <Select
            className='category-selection select'
            placeholder='Category'
            value={newCategorySelectValue}
            onChange={resp => {
              this.setState({ newCategorySelectValue: resp, newCategoryId: resp.value });
            }}
            options={categoryOptions}
          />
          <div className='input-row'>
            <input placeholder='Image URL' value={newImage} onChange={({ target }) => this.setState({ newImage: target.value })} />
            {selectedTicket && (
              <div onClick={() => this.setState({ newImage: selectedTicket.original_image || selectedTicket.image || '' })} className='icon'>
                <FontAwesomeIcon icon={faCopy}></FontAwesomeIcon>
              </div>
            )}
          </div>
          <div className='input-row multi'>
            <input
              placeholder='Fallback URL (optional)'
              value={newFallbackUrl}
              onChange={({ target }) => this.setState({ newFallbackUrl: target.value })}
            />
            <input placeholder='$' value={newFallbackPrice} onChange={({ target }) => this.setState({ newFallbackPrice: target.value })} />
          </div>
          <div className='checkbox-row'>
            <div className='label'>Hidden From Rankings</div>
            <input
              type='checkbox'
              name='check'
              checked={newHidden}
              onChange={e =>
                this.setState({
                  newHidden: e.target.checked
                })
              }
            />
          </div>
          <div className='checkbox-row'>
            <div className='label'>Pre-render for SEO</div>
            <input
              type='checkbox'
              name='check'
              checked={newIsSeoPrerendered}
              onChange={e =>
                this.setState({
                  newIsSeoPrerendered: e.target.checked
                })
              }
            />
          </div>
          <div className='submit-btns'>
            {this.isEditing() && (
              <div onClick={this.deleteProduct} className='delete icn'>
                <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
              </div>
            )}
            {this.canSave() && (
              <div onClick={this.submitForm} className='save btn'>
                {isSaving ? 'Saving...' : this.isEditing() ? 'Save' : 'Add'}
              </div>
            )}
            {selectedTicket && !this.isEditing() && this.canSave() && (
              <div onClick={e => this.submitForm(e, true)} className='save btn'>
                {isSaving ? 'Adding...' : 'Add and Match'}
              </div>
            )}
          </div>
        </form>
        {product && (
          <>
            <div className={cn('variants-list', { 'top-section': !this.selectedTicketIsPin() })}>
              <div className='variants-header'>
                <div className='main'>Variants</div>
                {selectedTicket && !this.selectedTicketIsPin() && (
                  <div className='make-match-btn' onClick={() => this.makeTicketMatch(selectedTicket, product)}>
                    Make Match
                  </div>
                )}
              </div>
              {variants.length ? (
                <div className='variants-container'>
                  {variants.map((variant, idx) => {
                    const { image, url, price, retailer, rawTitle, id } = variant;
                    return (
                      <div key={retailer + idx} className='variant-container'>
                        <img alt={retailer} onClick={() => this.copy(image)} className='img' src={image} />
                        <div>
                          <a target='_blank' rel='noopener noreferrer' className='retailer' href={url}>
                            {retailer}
                          </a>
                          <div className='price'>${price && price.toFixed(2)}</div>
                          <div className='raw-title'>{rawTitle}</div>
                          <div className='controls'>
                            <div className='control' onClick={() => this.copy(image)}>
                              Copy Image
                            </div>
                            <div
                              onClick={() => {
                                updateVariant(id, { Product_id: null }).then(
                                  resp => {
                                    this.syncProduct(product.id);
                                    this.props.syncVariantsQueue();
                                  },
                                  err => {
                                    cogoToast.error('There was an issue updating this variant.');
                                  }
                                );
                              }}
                              className='control'
                            >
                              Unlink
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                !selectedTicket && <div className='empty-msg'>No Variants Found</div>
              )}
            </div>
            {(!!pins.length || this.selectedTicketIsPin()) && (
              <div className={cn('pins-list', { 'top-section': this.selectedTicketIsPin() })}>
                <div className='pins-header'>
                  <div className='main'>Pins</div>
                  {selectedTicket && this.selectedTicketIsPin() && (
                    <div className='make-match-btn' onClick={() => this.makeTicketMatch(selectedTicket, product)}>
                      Make Match
                    </div>
                  )}
                </div>
                {!!pins.length && (
                  <div className='pins-container'>
                    {pins.map((pin, idx) => {
                      const { image, original_image, link, title, retailer, Collection_id, id } = pin;
                      return (
                        <div key={title + idx} className='pin-container'>
                          <img alt={retailer} onClick={() => this.copy(image)} className='img' src={original_image || image} />
                          <div>
                            <div className='title'>{title}</div>
                            <a target='_blank' rel='noopener noreferrer' className='link' href={link}>
                              {link
                                .replace('https://', '')
                                .replace('www.', '')
                                .slice(0, 16)}
                              ...
                            </a>
                            <div className='controls'>
                              <div className='control' onClick={() => this.copy(image)}>
                                Copy Image
                              </div>
                              {original_image && original_image !== image && (
                                <div className='control' onClick={() => this.copy(image)}>
                                  Copy Original Image
                                </div>
                              )}
                              <a
                                target='_blank'
                                rel='noopener noreferrer'
                                className='control'
                                href={`https://shopmy.us/collections/${Collection_id}`}
                              >
                                View Product
                              </a>
                              <div
                                onClick={() => {
                                  updatePin(id, { Product_id: null }).then(
                                    resp => {
                                      this.syncProduct(product.id);
                                      this.props.syncPinsQueue();
                                    },
                                    err => {
                                      cogoToast.error('There was an issue updating this pin.');
                                    }
                                  );
                                }}
                                className='control'
                              >
                                Unlink
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}

export default ProductEditView;
