import _ from 'lodash';
import { fetchVariantsQueue, fetchVariantsQueueFilters as fetchVariantsQueueFiltersAPI, fetchPinsQueue } from '../api/queues';

export const GET_VARIANTS_QUEUE_SUCCESS = 'GET_VARIANTS_QUEUE_SUCCESS';
export const GET_VARIANTS_QUEUE_FILTERS_REQUEST = 'GET_VARIANTS_QUEUE_FILTERS_REQUEST';
export const GET_VARIANTS_QUEUE_FILTERS_SUCCESS = 'GET_VARIANTS_QUEUE_FILTERS_SUCCESS';
export const GET_VARIANTS_QUEUE_FILTERS_FAILURE = 'GET_VARIANTS_QUEUE_FILTERS_FAILURE';
export const GET_PINS_QUEUE_SUCCESS = 'GET_PINS_QUEUE_SUCCESS';

export const getVariantsQueue = (params = {}) => async (dispatch, getState) => {
  const resp = await fetchVariantsQueue(params);
  return dispatch({
    type: GET_VARIANTS_QUEUE_SUCCESS,
    variants: _.get(resp, 'variants', [])
  });
};

export const getVariantsQueueFilters = (params = {}) => async (dispatch, getState) => {
  dispatch({ type: GET_VARIANTS_QUEUE_FILTERS_REQUEST });
  try {
    const resp = await fetchVariantsQueueFiltersAPI(params);
    return dispatch({
      type: GET_VARIANTS_QUEUE_FILTERS_SUCCESS,
      domains: _.get(resp, 'domains', []),
      brands: _.get(resp, 'brands', []),
      retailers: _.get(resp, 'retailers', [])
    });
  } catch (e) {
    return dispatch({
      type: GET_VARIANTS_QUEUE_FILTERS_FAILURE,
      error: e
    });
  }
};

export const getPinsQueue = (params = {}) => async (dispatch, getState) => {
  const resp = await fetchPinsQueue(params);
  return dispatch({
    type: GET_PINS_QUEUE_SUCCESS,
    pins: _.get(resp, 'pins', [])
  });
};
