import * as ActionTypes from '../actions/queueActions';

export const queue = (
  state = {
    variantTickets: [],
    variantTicketDomains: [],
    variantTicketBrands: [],
    variantTicketRetailers: [],
    variantTicketIsSearchingFilters: false,
    pinTickets: []
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.GET_VARIANTS_QUEUE_SUCCESS:
      return {
        ...state,
        variantTickets: action.variants
      };

    case ActionTypes.GET_VARIANTS_QUEUE_FILTERS_REQUEST:
      return {
        ...state,
        variantTicketIsSearchingFilters: true
      };

    case ActionTypes.GET_VARIANTS_QUEUE_FILTERS_SUCCESS:
      return {
        ...state,
        variantTicketIsSearchingFilters: false,
        variantTicketDomains: action.domains,
        variantTicketBrands: action.brands,
        variantTicketRetailers: action.retailers
      };

    case ActionTypes.GET_VARIANTS_QUEUE_FILTERS_FAILURE:
      return {
        ...state,
        variantTicketIsSearchingFilters: false
      };

    case ActionTypes.GET_PINS_QUEUE_SUCCESS:
      return {
        ...state,
        pinTickets: action.pins
      };

    default:
      return state;
  }
};
