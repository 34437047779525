import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import cogoToast from 'cogo-toast';
import classnames from 'classnames';
import { confirmAlert } from 'react-confirm-alert';
import './QueueTickets.scss';

import { updatePin } from '../../../api/pins';
import { updateVariant } from '../../../api/products';

import QueueTicket from './QueueTicket';

class QueueTickets extends Component {
  static propTypes = {
    queue: PropTypes.object.isRequired,
    syncVariantsQueue: PropTypes.func.isRequired,
    syncPinsQueue: PropTypes.func.isRequired,
    selectTicket: PropTypes.func.isRequired,
    searchVal: PropTypes.string.isRequired,
    updateSearchVal: PropTypes.func.isRequired,
    selectedTicket: PropTypes.object,
    selectedProduct: PropTypes.object
  };

  componentDidMount() {
    this.props.syncVariantsQueue();
    this.props.syncPinsQueue();

    this.searchTicketsDebouncer = AwesomeDebouncePromise(() => {
      const syncFn = this.isVariantsMode() ? this.props.syncVariantsQueue : this.props.syncPinsQueue;
      this.setState({ isSearching: true });
      syncFn().finally(() => {
        this.setState({ isSearching: false });
      });
    }, 500);
  }

  state = {
    mode: 'variants',
    isSearching: false,
    isPanelActive: false,
    isSavingPinTickets: false
  };

  isVariantsMode = () => this.state.mode === 'variants';

  orphan = ticket => {
    const updateFn = this.isVariantsMode() ? updateVariant : updatePin;
    const syncFn = this.isVariantsMode() ? this.props.syncVariantsQueue : this.props.syncPinsQueue;
    updateFn(ticket.id, { isOrphan: true }).then(
      resp => {
        syncFn();
      },
      err => {
        cogoToast.error('There was an issue orphaning this variant.');
      }
    );
  };

  kickBackToWorkers = ticket => {
    updateVariant(ticket.id, { requiresAdminAttention: false, adminMatchNotes: null }).then(this.props.syncVariantsQueue, err =>
      cogoToast.error('There was an issue orphaning this variant.')
    );
  };

  changeSearch = newVal => {
    this.setState({ isSearching: true });
    this.props.updateSearchVal(newVal);
    this.searchTicketsDebouncer();
  };

  changeSort = newVal => {
    this.props.updateQueueSortVal(newVal);
    this.searchTicketsDebouncer();
  };

  getSortOptions = () => {
    return [
      { value: 'createdAt', label: 'Created At' },
      { value: 'volume', label: 'Order Volume' },
      { value: 'clicks', label: '# Clicks' }
    ];
  };

  bulkMatchPins = () => {
    const { selectedProduct, queue } = this.props;
    const { pinTickets } = queue;

    const onAlertComplete = () => {
      this.setState({ isSavingPinTickets: false });
    };

    confirmAlert({
      title: 'Are you sure?',
      message: `This will match all ${pinTickets.length} pins to the selected product.`,
      buttons: [
        {
          label: 'No',
          className: 'cancel',
          onClick: onAlertComplete
        },
        {
          label: 'Yes',
          onClick: async () => {
            cogoToast.info(`Beginning Matching of ${pinTickets.length} Pin${pinTickets.length === 1 ? '' : 's'}`);
            for (let i = 0; i < pinTickets.length; i++) {
              const pin = pinTickets[i];
              await updatePin(pin.id, { Product_id: selectedProduct.id });
            }

            cogoToast.success(`Completed Matching!`);
            this.props.syncPinsQueue();
            onAlertComplete();
          }
        }
      ],
      onClickOutside: onAlertComplete,
      onKeypressEscape: onAlertComplete
    });
  };

  render() {
    const { queue, selectedTicket, searchVal, selectTicket, selectedProduct } = this.props;
    const { mode, isSearching, isPanelActive, isSavingPinTickets } = this.state;
    const { variantTickets, pinTickets } = queue;
    const sortOptions = this.getSortOptions();
    const curSortOption = sortOptions.find(opt => opt.value === this.props.queueSortVal);
    const showMatchAllBtn = !this.isVariantsMode() && !!selectedProduct && !!pinTickets.length && !selectedTicket;
    const isMatchAllBtnDisabled = isSearching || isSavingPinTickets;

    return (
      <div className={classnames('queue-tickets-outer-container', { active: isPanelActive, loading: isSearching || isSavingPinTickets })}>
        <div onClick={() => this.setState({ isPanelActive: !isPanelActive })} className='toggle-active-btn'>
          {isPanelActive ? 'Hide Tickets' : 'View Tickets'}
        </div>
        <div className='toolbar'>
          <div className='main'>
            <input placeholder='Search' value={searchVal} onChange={({ target }) => this.changeSearch(target.value)} />
            {!this.isVariantsMode() && (
              <div className='tickets-actions'>
                <Select
                  className='sort-select'
                  placeholder='Sort Order'
                  onChange={val => this.changeSort(val.value)}
                  options={sortOptions}
                  value={curSortOption || sortOptions[0]}
                />
                {showMatchAllBtn && (
                  <div
                    className={classnames('action-btn', { disabled: isMatchAllBtnDisabled })}
                    onClick={() => {
                      if (isMatchAllBtnDisabled) return;
                      this.setState({ isSavingPinTickets: true });
                      this.bulkMatchPins();
                    }}
                  >
                    Match All{` (${pinTickets.length})`}
                  </div>
                )}
              </div>
            )}
          </div>
          <div className='secondary'>
            <div
              onClick={() => {
                this.setState({ mode: 'variants' }, () => {
                  this.changeSearch('');
                });
              }}
              className={classnames('toggle-btn', { active: mode === 'variants' })}
            >
              Admin Variants
            </div>
            <div
              onClick={() => {
                this.setState({ mode: 'pins' }, () => {
                  this.changeSearch('');
                });
              }}
              className={classnames('toggle-btn', { active: mode === 'pins' })}
            >
              Pins
            </div>
          </div>
        </div>
        {mode === 'variants' ? (
          <div className='tickets-list'>
            {variantTickets && variantTickets.length
              ? variantTickets.map(ticket => {
                  const isSelected = !!selectedTicket && ticket.id === selectedTicket.id;

                  return (
                    <QueueTicket
                      ticket={ticket}
                      key={ticket.id}
                      isSelected={isSelected}
                      isVariant
                      selectTicket={() => {
                        selectTicket(isSelected ? null : ticket);
                      }}
                      orphanTicket={() => this.orphan(ticket)}
                      kickBackToWorkers={() => this.kickBackToWorkers(ticket)}
                    />
                  );
                })
              : !isSearching && <div className='empty-msg'>No results</div>}
          </div>
        ) : (
          <div className='tickets-list'>
            {pinTickets && pinTickets.length
              ? pinTickets.map(ticket => {
                  const isSelected = !!selectedTicket && ticket.id === selectedTicket.id;

                  return (
                    <QueueTicket
                      ticket={ticket}
                      key={ticket.id}
                      isSelected={isSelected}
                      isVariant={false}
                      selectTicket={() => {
                        selectTicket(isSelected ? null : ticket);
                      }}
                      orphanTicket={() => this.orphan(ticket)}
                    />
                  );
                })
              : !isSearching && <div className='empty-msg'>No results</div>}
          </div>
        )}
      </div>
    );
  }
}

export default QueueTickets;
