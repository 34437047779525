import APIClient from './index';
import _ from 'lodash';

export const prettifyImage = (url, options = {}) => {
  return new Promise((resolve, reject) => {
    APIClient.post(`Images/prettify`, { url, ...options })
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const flipImage = (url, options = {}) => {
  return new Promise((resolve, reject) => {
    APIClient.post(`Images/flip`, { url, ...options })
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const cropImage = (url, crop) => {
  return new Promise((resolve, reject) => {
    APIClient.post(`Images/crop`, { url, crop })
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};
